import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import UnderConstruction from "../components/UnderConstruction"

const Profile = () => {
    const { keycloak, initialized } = useKeycloak()

    return (
        <div>
            <UnderConstruction />
        </div>
    )
}

export default Profile
